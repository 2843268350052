export const ProjectDescription = {
    bioanalytics: "As an intern at BioAnalytics, I performed market research and analysis on the current state of sleeping disorders to help aid their pre-market strategy for a novel device. From this research, I originated designs for an application to interface with the device. Throughout my BioEngineering degree we focused obsessively on the needs of the end user and there was no difference here - a highly contrasted colour palette was used to aid the visually impaired and daily metric updates sought to create a personalised experience. I built mockups through Framer and Figma and presented them to the c-suite at the conclusion of my time with the company.",
    art: "Spending a lot of time on the screen these days my hobbies are really becoming important again. I've always loved the visual arts, especially drawing and photography. Lately, I've also been a bit obsessed with making the perfect loaf of bread.",
    tiles: "",
    thesis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    dish: "DISH.ai is a fresh venture of mine. It is a shared calendar app that uses large language models to help users discover new recipes tailored to their own particular taste.  We aim to be as helpful as possible - auto-generating a users grocery list from their meal plans that can be forwarded to a variety of grocery delivery services (woolworths, coles, IGA). This venture is so fresh I struggle to think it warrants a tile on my work page but I'm just that excited to get building with some talented friends. Come back soon to see the progress!"

}

export const ProjectImage = {
    bioanalytics: process.env.PUBLIC_URL + '/assets/bioanalytics.gif',
    tiles: process.env.PUBLIC_URL + '/assets/tiles.gif',
    thesis: process.env.PUBLIC_URL + '/assets/thesis.png',
    art: process.env.PUBLIC_URL + '/assets/art_detail.png',
    dither: process.env.PUBLIC_URL + '/assets/dither_detail.png',
    dish: process.env.PUBLIC_URL + '/assets/dish_detail.png'
}

export const ProjectName = {
    bioanalytics: "Bioanalytics",
    tiles: "Creative Coding",
    thesis: "Honours Thesis",
    art: "Outside of Work",
    dither: "dither_",
    dish: "DISH.ai"
}